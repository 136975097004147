import Router from '../router';
import axios from 'axios';
import store from "../store/index.js";
/* 定时器变量、超时时间 */
axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
axios.defaults.baseURL = '/apis/';
axios.interceptors.request.use(
    config => {
        // 处理get和post传参params的区别   追加token   
        if (config.method == 'get') {
            config.params = config.params ? config.params : {}
            config.params['token'] = config.params['token'] || localStorage.token;
            config.params['lang_data'] = config.params['lang_data'] || localStorage.lang_data;
            config.params['device_port'] = 'pc';
        } else {
            config.data = config.data ? config.data : {}
            config.data['token'] = config.data['token'] || localStorage.token;
            config.data['lang_data'] = config.data['lang_data'] || localStorage.lang_data;
            config.data['device_port'] = 'pc';
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    });
axios.interceptors.response.use(
    response => {
        let res = response.data;
        if (res.code == '990016' || res.code == '990013') {
            store.dispatch("clearLoginInfo")
            Router.push({ name: 'login', params: { status: 1 } });
            return Promise.reject('');
        } else if (res.code == 'E10000') {
            // 请求频繁，不做处理
            return Promise.reject('');
        }
        return response.data;
    },
    error => {
        return Promise.reject(error);
    });
export { axios };
