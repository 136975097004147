import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "@/axios/index.js";
import '@/assets/css/common.scss';
import Public from '@/assets/js/public.js';
import moment from "moment"
import axios from "axios";
import './element-variables.scss'
Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.$moment = moment;
Vue.use(ElementUI);
Vue.use(Public);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
